import { Flex, Text } from "@chakra-ui/react";
import { IssueTag } from "@/components/IssueTag";
import { StageTagMappings } from "@/types/issue";

import styles from "./styles.module.scss";
import { formatActivityTimestamp } from "@/utils/date_helpers";

interface IVersionChangeProps {
  versionChange: IIssueVersionChange;
}

const Timestamp = (props: { date: Date }): JSX.Element => (<Text className={styles.timestamp}>{ formatActivityTimestamp(props.date) }</Text>);

const StageChange = (props: { newValue: IssueStage }): JSX.Element => (
  <Flex flexDirection="row" alignItems="center">
    <Text style={{ whiteSpace: "pre-wrap" }}>Issue <span className={styles.columnChanged}>stage</span> changed to </Text>
    <IssueTag
      text={StageTagMappings[props.newValue].label}
      textColor={StageTagMappings[props.newValue].textColor}
      backgroundColor={StageTagMappings[props.newValue].backgroundColor}
    />
  </Flex>
)

const AssigneeChange = (props: { newValue: string }): JSX.Element => (
  <Flex flexDirection="row" alignItems="center">
    <Text style={{ whiteSpace: "pre-wrap" }}>Issue <span className={styles.columnChanged}>assigned to</span> </Text>
    <Text>{props.newValue}</Text>
  </Flex>
)

const CreatedBy = (props: { newValue: string }): JSX.Element => (
  <Flex flexDirection="row" alignItems="center">
    <Text style={{ whiteSpace: "pre-wrap" }}>Issue <span className={styles.columnChanged}>created by</span> </Text>
    <Text>{props.newValue}</Text>
  </Flex>
)

const TitleChange = (props: { newValue: string }): JSX.Element => (
  <Flex flexDirection="row" alignItems="center">
    <Text style={{ whiteSpace: "pre-wrap" }}>Issue <span className={styles.columnChanged}>title</span> changed to </Text>
    <Text>{props.newValue}</Text>
  </Flex>
)

const VersionChange = (props: IVerisonChangeProps) => {
  const { versionChange } = props;

  return (
    <Flex className={styles.content}>
      <Flex width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" padding="12px">
        {
          versionChange.column === "current_stage" ? (
            <StageChange newValue={versionChange.newValue} />
          ) : null
        }

        {
          versionChange.column === "assignee_id" ? (
            <AssigneeChange newValue={versionChange.newValue?.name} />
          ) : null
        }

        {
          versionChange.column === "created_by" ? (
            <CreatedBy newValue={versionChange.newValue?.name} />
          ) : null
        }

        {
          versionChange.column === "title" ? (
            <TitleChange newValue={versionChange.newValue} />
          ) : null
        }

        <Timestamp date={versionChange.createdAt} />
      </Flex>
    </Flex>
  );
};

export default VersionChange;
