import { Outlet } from "react-router-dom";
import { ChakraProvider, extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { Helmet, HelmetData } from 'react-helmet-async';


const helmetData = new HelmetData({});

const App = () => {
  const theme = extendTheme(
    {
      colors: {
        // Put #6262f8 in https://m2.material.io/inline-tools/color/
        primary: {
         50: "#EAEAFF",
         100: "#E0E0FE",
         200: "#C1C0FC",
         300: "#A1A1FB",
         400: "#8281F9",
         500: "#6362F8",
         600: "#4F4EC6",
         700: "#3B3B95",
         800: "#282763",
         900: "#141432",
       },
       secondary: {
        lightGray: "#F9FAFB",
        gray: "#D9DEE0",
        darkGray: "#9596A0",
        deepGray: "#4F575E",
        green: "#4FC980",
        yellow: "#F9CC5A",
        red: "#EF6666",
        orange: "#F99D5A",
       }
      },
      fonts: {
        heading: "DM Sans",
        body: "DM Sans",
      },
      components: {
        Input: {
          defaultProps: {
            focusBorderColor: "primary.500",
          }
        },
        Select: {
          defaultProps: {
            focusBorderColor: "primary.500",
          }
        },
        Textarea: {
          defaultProps: {
            focusBorderColor: "primary.500",
          }
        },
        FormLabel: {
          baseStyle: {
            fontSize: "12px",
            fontWeight: "normal",
          }
        }
      }
    },
    withDefaultColorScheme({
      colorScheme: 'primary'
    }),
  );

  return (
    <ChakraProvider theme={theme}>
      <Helmet helmetData={helmetData}>
        <meta charSet="UTF-8"/>
        <link rel="icon" type="image/svg+xml" href={`${import.meta.env.VITE_FAVICON}`}/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>BrightGo</title>
      </Helmet>
      <Outlet />
    </ChakraProvider>
  )
};

export default App;
