import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

import { Button, Box, Flex, Heading, Text, useTheme } from "@chakra-ui/react";
import { AiOutlineReload } from "react-icons/ai";

import { useAuth } from "@/contexts/auth_context";

import { AttendanceTaskHistory } from "@/components/AttendanceTaskHistory";
import { EventFilter } from "@/components/EventFilter";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";

import styles from "./styles.module.scss";

const TaskHistoryPage = () => {
  const authContext = useAuth();
  const theme = useTheme();
  const organizationInfo = authContext?.state?.userToken?.organization;
  // Filtering
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusFilteredTaskIds, setStatusFilteredTaskIds] = useState<Set<string>>(new Set());
  const [employeeFilteredTaskIds, setEmployeeFilteredTaskIds] = useState<Set<string>>(new Set());
  const [assigneeFilteredTaskIds, setAssigneeFilteredTaskIds] = useState<Set<string>>(new Set());
  const [resetKey, setResetKey] = useState<boolean>(false);

  const getAttendanceTaskUrl = (organization_id: number) => {
    return `${import.meta.env.VITE_API_SERVER}/mobile/v1/attendance_tasks?organization_id=${organization_id}`
  }

  const { data, error, isLoading } = useSWR(
    getAttendanceTaskUrl(organizationInfo.id),
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  )

  if (isLoading) {
    return <FullScreenSpinner />
  }

  // Unique identifier for each task
  const getTaskId = (task) => {
    return task.id;
  };

  const taskList = Object.values(data.tasks).map(obj => ({
    ...obj,
    status: !!obj.completedAt ? "completed" : "in_progress"
  }))

  const filteredTasks = taskList.filter(task =>
    statusFilteredTaskIds.has(task.id) &&
    employeeFilteredTaskIds.has(task.id) &&
    assigneeFilteredTaskIds.has(task.id)
  )

  const sortedTasks = !filteredTasks ?
    [] :
    filteredTasks.sort((a, b) => (
      new Date(b.createdAt) - new Date(a.createdAt)
    ))

  const taskIdSetsFromOtherFilters = (taskIdSetForCurrentFilter) => {
    return [statusFilteredTaskIds, employeeFilteredTaskIds, assigneeFilteredTaskIds]
      .filter((taskIdSet) => {
        return taskIdSet != taskIdSetForCurrentFilter;
      });
  }

  const rerenderFilters = () => {
    // hide all events before rerendering to avoid flickering.
    setStatusFilteredTaskIds(new Set());
    setEmployeeFilteredTaskIds(new Set());
    setAssigneeFilteredTaskIds(new Set());
    setResetKey(!resetKey);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Heading className={styles.text} size="lg">Attendance Task History</Heading>
      </div>
      <div className={styles.tasksContainer}>
        <Flex className={styles.filterContainer} alignItems="center" justifyContent='space-between'>
          <Flex alignItems={"center"}>
            <Text fontSize='lg' style={{ marginRight: 10 }}>Filter by:</Text>
            <Box mr={6}>
              <EventFilter
                key={"StatusFilter" + resetKey}
                events={taskList}
                eventIdSetsFromOtherFilters={taskIdSetsFromOtherFilters(statusFilteredTaskIds)}
                getFilterKey={(task) => (task.status)}
                getEventId={getTaskId}
                convertFilterKeyToLabel={(filterKey) => {
                  switch (filterKey) {
                    case "in_progress":
                      return "In Progress"
                    case "completed":
                      return "Completed";
                    default:
                      return filterKey;
                  }
                }}
                updateEvent={setStatusFilteredTaskIds}
                filterName={"Task Status"}
                width={160}
              />
            </Box>
            <Box mr={6}>
              <EventFilter
                key={"Employee" + resetKey}
                events={taskList}
                eventIdSetsFromOtherFilters={taskIdSetsFromOtherFilters(employeeFilteredTaskIds)}
                getFilterKey={(task) => { return task.employee.name }}
                getEventId={getTaskId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={setEmployeeFilteredTaskIds}
                filterName={"Cleaner"}
                width={160}
              />
            </Box>
            <Box mr={6}>
              <EventFilter
                key={"Assignee" + resetKey}
                events={taskList}
                eventIdSetsFromOtherFilters={taskIdSetsFromOtherFilters(assigneeFilteredTaskIds)}
                getFilterKey={(task) => { return task.assignee.name }}
                getEventId={getTaskId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={setAssigneeFilteredTaskIds}
                filterName={"Assignee"}
                width={160}
              />
            </Box>

            <Button
              variant={"ghost"}
              onClick={() => {
                setSearchParams('');
                rerenderFilters();
              }}
            >
              Clear all
            </Button>
          </Flex>
        </Flex>
        <AttendanceTaskHistory tasks={sortedTasks} expanded={true} />
      </div>
    </div>
  )
}

export default TaskHistoryPage;
