import {
  Tag,
  TagLabel,
} from "@chakra-ui/react";

const ShiftStatus = ({eventStatus}: {eventStatus: string}) => {
  switch (eventStatus) {
    case "completed":
      return (
        <Tag size="sm" colorScheme="green" borderRadius="full">
          <TagLabel>Completed</TagLabel>
        </Tag>
      );
    case "clocked_in":
      return (
        <Tag size="sm" colorScheme="blue" borderRadius="full">
          <TagLabel>Clocked In</TagLabel>
        </Tag>
      );
    case "upcoming":
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>Upcoming</TagLabel>
        </Tag>
      );
    case "no_show":
      return (
        <Tag size="sm" colorScheme="red" borderRadius="full">
          <TagLabel>No Show</TagLabel>
        </Tag>
      );
    case "not_tracked":
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>Not Tracked</TagLabel>
        </Tag>
      );
    case "not_installed":
      return (
        <Tag size="sm" colorScheme="yellow" borderRadius="full">
          <TagLabel>Not Installed</TagLabel>
        </Tag>
      );
    default:
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>{eventStatus}</TagLabel>
        </Tag>
      );
  }
}

export default ShiftStatus;
