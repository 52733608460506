import { useAuth } from "@contexts/auth_context";
import {
  Heading, Divider,
  Flex, Spacer,
  ButtonGroup, Button,
} from "@chakra-ui/react";

import styles from "./styles.module.scss";
import {
  addDays, subDays,
  format, set, compareAsc, differenceInMinutes
} from "date-fns";
import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  TagLabel,
  Checkbox,
} from '@chakra-ui/react';
import {AiFillLeftCircle, AiFillRightCircle} from "react-icons/ai";
import { FullScreenSpinner } from '@/components/FullScreenSpinner';
import { ErrorPage } from '@/components/ErrorPage';
import useSWR from 'swr';

const numberOfDays = 7;
const getUrl = (supervisorId: number, inputDate: Date) => {
  const startDatetime = subDays(inputDate, numberOfDays).toISOString();
  const endDatetime = inputDate.toISOString();

  return `${import.meta.env.VITE_API_SERVER}/assignments/clock_ins_for_team.json?supervisor_id=${supervisorId}&start_datetime=${encodeURIComponent(
    startDatetime
  )}&end_datetime=${encodeURIComponent(endDatetime)}`;
}

const computeEmployeeStatus = (inputDate, assignments) => {
  const statuses = [];
  if (!assignments) {
    assignments = [];
  }
  assignments = assignments.filter((assignment) => assignment.status !== "upcoming");
  for(let i = numberOfDays; i > 0; i--) {
    const today = subDays(inputDate, i);
    const nextDay = subDays(inputDate, i - 1);
    const assignmentsForDay = assignments.filter((assignment) => {
      const eventStartTime = new Date(assignment.eventInfo.calendarTime)
      return compareAsc(eventStartTime, today) >= 0 && compareAsc(eventStartTime, nextDay) < 0;
    });
    const statusForDay = computeEmployeeStatusForSingleDay(assignmentsForDay);
    statuses.push({date: today, status: statusForDay, assignments: assignmentsForDay});
  }
  return statuses;
}

const countShifts = (assignments) => {
  const scheduledShifts = assignments.filter((assignment) => assignment.status !== "upcoming").length;
  const clockedInShift = assignments.filter((assignment) => assignment.status === "completed" || assignment.status === "clocked_in").length;
  return {clockedInShift, scheduledShifts}
}

const countHours = (assignments) => {
  let scheduledHours = 0;
  let completedHours = 0;
  assignments.forEach((event) => {
    const eventInfo = event.eventInfo;
    const shift = event.clockedShift;
    scheduledHours += eventInfo.duration / 60 / 60;
    if (event.status == 'completed') {
      completedHours += differenceInMinutes(new Date(shift.clockOutTimestamp), new Date(shift.clockInTimestamp)) / 60;
    }
  });

  return { completedHours: Math.round(completedHours * 2) / 2, scheduledHours}
}

const computeEmployeeStatusForSingleDay = (assignments) => {
  if (assignments.length === 0) {
    return "N/A";
  }
  if (assignments.some((assignment) => assignment.status === "no_show")) {
    return "no_show";
  }
  if (assignments.some((assignment) => assignment.status === "clocked_in")) {
    return "clock_in_only";
  }
  if (assignments.some((assignment) => assignment.status === "not_tracked")) {
    return "not_tracked";
  }
  if (assignments.every((assignment) => assignment.status === "completed")) {
    return "completed";
  }
  return "unknown";
}

const getStatusPill = (status) => {
  switch (status) {
    case "completed":
      return (
        <Tag size="sm" colorScheme="green" borderRadius="full">
          <TagLabel>completed</TagLabel>
        </Tag>
      );
    case "no_show":
      return (
        <Tag size="sm" colorScheme="red" borderRadius="full">
          <TagLabel>no show</TagLabel>
        </Tag>
      );
    case "clock_in_only":
      return (
        <Tag size="sm" colorScheme="orange" borderRadius="full">
          <TagLabel>clock-in only</TagLabel>
        </Tag>
      );
    case "not_tracked":
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>not tracked</TagLabel>
        </Tag>
      );
    default:
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>{status}</TagLabel>
        </Tag>
      );
  }
}

const canonicalizeAppVersion = (version) => {
  // 0.1.3.0 is the last release that doesn't send up version
  if (!version) {
    return "0.1.3.0"
  }
  const segments = version.split(".");
  if (segments.length === 3) {
    return version + ".0";
  }
  return version;
}

const EmployeeReport = () => {
  const authContext = useAuth();
  const userInfo = authContext.state.userToken.user_info;

   // Assume input is in PT (my device time). UTC should be +8 hrs
   // Need to set milliseconds here because we are using the URL as the key for SWR
  const [startDatetime, setStartDatetime] = useState<Date>(set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}))
  const [showFewerColumns, setShowFewerColumns] = useState(false);

  const shiftDateBackwards = () => {
    // Clear all events first otherwise the page will flicker
    setStartDatetime(subDays(startDatetime, 1));
  }

  const shiftDateForwards = () => {
    // Clear all events first otherwise the page will flicker
    setStartDatetime(addDays(startDatetime, 1));
  }

  const resetDateToToday = () => {
    // Clear all events first otherwise the page will flicker
    setStartDatetime(set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}));
  }

  const fetchClockInsForTeam = async (url: String) => {
    const response = await authContext.authenticatedFetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const employees = data.employees.reduce((map, employee) => {
      map[employee.id] = employee;
      return map;
    }, {});

    let assignmentsByEmployee = data.assignmentsByEmployee || {};

    // Remove anyone who is not scheduled for an assignment
    assignmentsByEmployee = Object.keys(assignmentsByEmployee)
      .filter((key) => assignmentsByEmployee[key].length > 0)
      .reduce((obj, key) => {
        obj[key] = assignmentsByEmployee[key];
        return obj;
      }, {});
    return { employees, assignmentsByEmployee };
  };

  const { data, error, isLoading } = useSWR(
    getUrl(userInfo.id, startDatetime),
    url => fetchClockInsForTeam(url)
  );

  if (error) {
    return <ErrorPage error={error}/>;
  }

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  const employeeStatus: { [key: number]: string[] } = {};
  const { assignmentsByEmployee, employees } = data;
  Object.keys(employees).forEach((employee_id) => {
    employeeStatus[employee_id] = computeEmployeeStatus(startDatetime, assignmentsByEmployee[employee_id]);
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Flex minWidth='max-content' alignItems='center' gap='2'>
          <Spacer />

          <Heading size='lg'>
            Employee Report
          </Heading>

          <Spacer />

          <Checkbox
            isChecked={showFewerColumns}
            onChange={() => setShowFewerColumns(!showFewerColumns)}
            style={{ marginRight: '5px' }}
          >
            Hide details
          </Checkbox>
          <ButtonGroup variant='outline' spacing='6' isAttached>
            <Button onClick={shiftDateBackwards}>
              <AiFillLeftCircle size={24} />
            </Button>
            <Button onClick={resetDateToToday}>
              Today
            </Button>
            <Button onClick={shiftDateForwards}>
              <AiFillRightCircle size={24} />
            </Button>
          </ButtonGroup>

        </Flex>

        <Divider style={ {marginTop: 10, marginBottom: 10} } />
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <Thead>
            <Tr>
              <Th key="name" maxW="100px">Name</Th>
              <Th key="supervisor" maxW="100px">Supervisor</Th>
              <Th key="missedCount" maxW="100px">Missed shifts</Th>
              <Th key="completedHours" maxW="100px">Completed hours</Th>
              <Th key="scheduledHours" maxW="100px">Scheduled hours</Th>
              {showFewerColumns ? null: (
                <>
                <Th key="lastLogin" maxW="100px">Last Login</Th>
                <Th key="appVersion" maxW="100px">App Version</Th>
                {Object.values(employeeStatus)[0].map((statusObj, index) => (
                  <Th key={`status_${index}`} maxW="100px">
                    {format(new Date(statusObj.date), "yyyy-MM-dd (iii)")}
                  </Th>
                ))}
                </>)}
              <Th key="assignments" maxW="100px">Locations</Th>
              <Th key="phoneNumber" maxW="100px">Phone Number</Th>
              <Th key="username" maxW="100px">Username</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.keys(employees).map((employeeId) => {
              const employee = employees[employeeId];
              const shiftStatuses = employeeStatus[employeeId];
              if (employee.orgAdmin) {
                return null;
              }
              if (shiftStatuses.every((statusObj) => statusObj.status === "N/A")) {
                return null;
              }
              const allLocations = new Set();
              shiftStatuses.forEach((status) => { status.assignments.forEach((assignment) => { allLocations.add(assignment.location.name) }) });
              const { clockedInShift, scheduledShifts} = countShifts(assignmentsByEmployee[employeeId]);
              const { completedHours, scheduledHours } = countHours(assignmentsByEmployee[employeeId]);
              return (
              <Tr key={employeeId}>
                <Td key={`name_${employeeId}`} minW="100px">{employee.name}</Td>
                <Td key={`supervisor_${employeeId}`} minW="100px">{employee.supervisorName}</Td>
                <Td key={`missedCount${employeeId}`} minW="100px">{scheduledShifts - clockedInShift}</Td>
                <Td key={`completedHours${employeeId}`} minW="100px">{completedHours}</Td>
                <Td key={`scheduledHours${employeeId}`} minW="100px">{scheduledHours}</Td>
                {showFewerColumns ? null: (
                  <>
                    <Td key={`lastLogin_${employeeId}`} minW="100px">{employee.lastClientRequestAt ?
                    format(new Date(employee.lastClientRequestAt), "yyyy-MM-dd HH:mm") : "Never"}</Td>
                    <Td key={`appVersion_${employeeId}`} minW="100px">{employee.lastClientRequestAt ? canonicalizeAppVersion(employee.clientAppVersion) : "N/A"}</Td>
                    {shiftStatuses.map((statusObj, index) => (
                      <Td key={`status_${employeeId}_${index}`} minW="100px">{getStatusPill(statusObj.status)}</Td>
                    ))}
                  </>)}
                <Td key={`assigments_${employeeId}`} minW="100px">{Array.from(allLocations).join(", ")}</Td>
                <Td key={`phoneNumber_${employeeId}`} minW="100px">{employee.phoneNumber}</Td>
                <Td key={`username_${employeeId}`} minW="100px">{employee.username}</Td>
              </Tr>);
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default EmployeeReport;
