import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Box,
  Flex,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import { format, parseISO } from "date-fns";
import { formatDuration, renderUserName } from '@/utils/formatting';
import { DateTimePicker } from '../DateTimePicker';


const daysMapping = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const renderCalendarStartDatetime = (calendarStartDatetime) => {
  if (calendarStartDatetime === null) {
    return null;
  }
  return format(calendarStartDatetime, "hh:mm a");
}

const renderDaysOfWeek = (daysOfWeek) => {
  let daysOfWeekText = "";
  daysMapping.forEach((day, index) => {
    if (daysOfWeek.includes(index)) {
      daysOfWeekText += `${day} `;
    }
  })
  return daysOfWeekText;
}

const ApplyOptions = {
  UpdateUntil: "update-until",
  UpdateRemainingUnfilled: "update-remaining-unfilled",
  UpdateAll: "update-all",
};

const ConfirmationModal = ({
  isOpen,
  confirmationModalType,
  onClose,
  onConfirm,
  minApplyToDate,
  defaultApplyToDate,
  defaultApplyFromDate,
  originalEvent,
  updatedEvent,
  users,
  locations}) => {

  const [applyToDate, setApplyToDate] = useState(defaultApplyToDate);
  const [applyFromDate, setApplyFromDate] = useState(defaultApplyFromDate);
  const defaultApplyToSelection = confirmationModalType === "Create" ? ApplyOptions.UpdateAll : ApplyOptions.UpdateUntil
  const [applyToSelection, setApplyToSelection] = useState(defaultApplyToSelection);
  useEffect(() => {
    setApplyToSelection(defaultApplyToSelection);
  }, [defaultApplyToSelection]);
  const unfilledEndDate = (originalEvent.recurrenceEndDatetime && !originalEvent.employeeId && updatedEvent.employeeId)
  ? new Date(originalEvent.recurrenceEndDatetime) : null;
  const toast = useToast();

  const getTitle = () => {
    switch (confirmationModalType) {
      case "Delete":
        return "Delete schedule";
      case "Edit":
        return "Save changes";
      case "Create":
        return "Confirm new schedule"
      default:
        return "";
    }
  }

  const renderEditDiff = (isCreate) => {
    const renderDiffText = (attributeName, originalValue, updatedValue) => {
      return (
        <Flex flexDirection={'column'} my={2}>
          <Text fontSize={12}>{attributeName}</Text>
          <Flex>
            <Text mr={1} fontSize={14} >{updatedValue}</Text>
            {(originalValue === updatedValue || isCreate) ? null : <Text fontSize={14} as="del">{originalValue}</Text>}
          </Flex>
        </Flex>
      )
    }

    const renderDayOfWeekDiffText = (originalDaysOfWeek, newDaysOfWeek) => {
      const textList = [];

      daysMapping.forEach((day, index) => {
        if (newDaysOfWeek.includes(index)) {
          textList.push(<Text key={index} fontSize={14} mr={1}>{day}</Text>);
        } else if (originalDaysOfWeek.includes(index)) {
          textList.push(<Text key={index} fontSize={14} mr={1} as="del">{day}</Text>);
        }
      })

      return (
        <Flex flexDirection={'column'} my={2}>
          <Text fontSize={12}>Repeats on</Text>
          <Flex>
            {textList}
          </Flex>
        </Flex>
      )
    }

    return (
      <>
        {renderDiffText("Location", locations[originalEvent.locationId]?.name, locations[updatedEvent.locationId].name)}
        {renderDiffText("Supervisor", renderUserName(users, originalEvent.supervisorId), renderUserName(users, updatedEvent.supervisorId))}
        {renderDiffText("Cleaner", renderUserName(users, originalEvent.employeeId), renderUserName(users, updatedEvent.employeeId))}
        {renderDayOfWeekDiffText(originalEvent.daysOfWeek, updatedEvent.daysOfWeek)}
        {renderDiffText("Starts at", renderCalendarStartDatetime(originalEvent.calendarStartDatetime), renderCalendarStartDatetime(updatedEvent.calendarStartDatetime))}
        {renderDiffText("Duration", formatDuration(originalEvent.calendarDuration), formatDuration(updatedEvent.calendarDuration))}
      </>
    );
  }

  const renderDeleteDiff = () => {
    const renderDiffText = (attributeName, attributeValue) => {
      return (
        <Flex flexDirection={'column'} my={2}>
          <Text fontSize={12}>{attributeName}</Text>
          <Flex>
            <Text mr={1} fontSize={14} >{attributeValue}</Text>
          </Flex>
        </Flex>
      )
    }
    return (
      <>
        {renderDiffText("Location", locations[originalEvent.locationId].name)}
        {renderDiffText("Supervisor", renderUserName(users, originalEvent.supervisorId))}
        {renderDiffText("Cleaner", renderUserName(users, originalEvent.employeeId))}
        {renderDiffText("Repeats on", renderDaysOfWeek(originalEvent.daysOfWeek))}
        {renderDiffText("Starts at", renderCalendarStartDatetime(originalEvent.calendarStartDatetime))}
        {renderDiffText("Duration", formatDuration(originalEvent.calendarDuration))}
      </>
    );
  }

  const renderDiff = () => {
    switch (confirmationModalType) {
      case "Delete":
        return renderDeleteDiff();
      case "Edit":
        return renderEditDiff(false);
      case "Create":
        return renderEditDiff(true);
      default:
        return null;
    }
  }

  const renderRangeSelectionForUnfilled = () => {
    if (!unfilledEndDate) {
      return null;
    }
    if (originalEvent.previousEmployeeId) {
      return (
        <Radio value={ApplyOptions.UpdateRemainingUnfilled} my={1}>
          update this and following events until {renderUserName(users, originalEvent.previousEmployeeId)} returns on {format(unfilledEndDate, "MM/dd/yyyy")}
        </Radio>
      )
    }
    return (
      <Radio value={ApplyOptions.UpdateRemainingUnfilled} my={1}>
        update this and following events until the next cleaner starts on {format(unfilledEndDate, "MM/dd/yyyy")}
      </Radio>
    )
  }

  const renderRangeSelection = () => {
    if (confirmationModalType === "Edit") {
      return (
        <Flex flexDirection="column">
          <RadioGroup
            mt={4}
            mb={2}
            value={applyToSelection}
            onChange={(value) => setApplyToSelection(value)}
          >
            <Stack>
              <Stack my={1} direction="row" alignItems="center">
                <Radio value={ApplyOptions.UpdateUntil}>update this and following events until </Radio>
                <Box
                  w={32}
                >
                  <DateTimePicker
                    disabled={applyToSelection !== ApplyOptions.UpdateUntil}
                    showTime={false}
                    showDate={true}
                    value={applyToDate}
                    onChange={setApplyToDate}
                  />
                </Box>
              </Stack>
            </Stack>
            {renderRangeSelectionForUnfilled()}
            <Radio value={ApplyOptions.UpdateAll} my={1}>update this and all following events</Radio>
          </RadioGroup>
        </Flex>
      );
    }
    if (confirmationModalType === "Create") {
      return (
        <Flex flexDirection="row" mt={4} mb={2} width={"100%"}>
          <Stack mr={2} direction="column" alignItems="left" flex={1}>
            <Text>Starts on</Text>
            <Box
              w={"100%"}
              ml={6}
            >
              <DateTimePicker
                showTime={false}
                showDate={true}
                value={applyFromDate}
                alignment='left'
                onChange={setApplyFromDate}
              />
            </Box>
          </Stack>

          <Stack mr={2} direction="column" alignItems="left" flex={1}>
            <Text>Ends on</Text>
            <Box
              w={"100%"}
            >
              <DateTimePicker
                showTime={false}
                disabled={applyToSelection !== ApplyOptions.UpdateUntil}
                showDate={true}
                value={applyToDate}
                alignment='left'
                onChange={setApplyToDate}
              />
            </Box>
            <Checkbox
              isChecked={applyToSelection === ApplyOptions.UpdateAll}
              onChange={(event) => {
              if (event.target.checked) {
                setApplyToSelection(ApplyOptions.UpdateAll);
              } else {
                setApplyToSelection(ApplyOptions.UpdateUntil);
              }
            }}>Never</Checkbox>
          </Stack>
        </Flex>
      );
    }
    return null;
  }

  const renderButtons = () => {
    switch (confirmationModalType) {
      case "Delete":
        return (
        <Box
          flex={1}
        >
          <Button
            flex={1} width={'100%'} variant='solid' colorScheme='red' mb={4}
            onClick={() => {
              onConfirm();
          }}>
            Delete all events
          </Button>
          <Button
            width={'100%'} flex={1} variant='solid' colorScheme='red' mb={2}
            onClick={() => {
              onConfirm(defaultApplyFromDate, null);
          }}>
          Delete all events from {format(defaultApplyFromDate, "MMM d (EEE)")}
          </Button>
        </Box>);
      case "Edit":
        return (
        <Button flex={1} variant='solid' mb={2}
          onClick={() => {
            validateAndConfirm()
        }}>
          Update
        </Button>)
      case "Create":
        return (
        <Button flex={1} variant='solid' mb={2}
          onClick={() => {
            validateAndConfirm()
        }}>
          Create
        </Button>)
      default:
        return null;
    }
  }

  const validateAndConfirm = () => {
    if (applyToSelection === ApplyOptions.UpdateUntil && applyToDate) {
      if (applyToDate <= applyFromDate) {
        toast({
          description: "End date must be after start date.",
          position: "bottom",
          status: "error",
          variant: "left-accent",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      onConfirm(applyFromDate, applyToDate);
    } else if (applyToSelection === ApplyOptions.UpdateAll) {
      onConfirm(applyFromDate, null);
    } else if (applyToSelection === ApplyOptions.UpdateRemainingUnfilled && unfilledEndDate) {
      onConfirm(applyFromDate, unfilledEndDate);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setApplyToSelection(defaultApplyToSelection);
      }}
      size={"lg"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getTitle()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box
            borderRadius="lg"
            border="1px"
            borderColor="gray.200"
            p={4}
            backgroundColor="gray.100"
            width="l"
          >
            {renderDiff()}
          </Box>
            {renderRangeSelection()}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup width="100%" spacing='6'>
              {renderButtons()}
            </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
