import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { addDays, subDays, set } from "date-fns";

import { Spinner } from '@chakra-ui/react';

import { useAuth } from '@/contexts/auth_context';
import { ClockInEmployeeCard } from '../ClockInEmployeeCard';

import styles from "./styles.module.scss";

const ShiftHistory = ({ userId }) => {
    const authContext = useAuth();

    const [startDatetime, setStartDatetime] = useState<Date>(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }))
    const [employee, setEmployee] = useState(null)
    const [shifts, setShifts] = useState([])

    const getUrl = (employeeId: number, inputDate: Date) => {
        const startDatetime = subDays(inputDate, 14).toISOString();
        const endDatetime = addDays(inputDate, 1).toISOString();

        return (
            `${import.meta.env.VITE_API_SERVER}/assignments/clock_ins_for_team.json` +
            `?employee_id=${employeeId}` +
            `&start_datetime=${encodeURIComponent(startDatetime)}` +
            `&end_datetime=${encodeURIComponent(endDatetime)}`
        );
    }

    const fetchClockInsForTeam = async (url: String) => {
        const response = await authContext.authenticatedFetch(url);
        const data = response.json();

        return data
    }

    const { data, error, isLoading } = useSWR(
        getUrl(userId, startDatetime),
        url => fetchClockInsForTeam(url)
    )

    useEffect(() => {
        if (!data) { return }
        setEmployee(data.employees[0])

        if (!data.assignmentsByEmployee) { return }
        var unsortedShifts = data.assignmentsByEmployee[userId]
        setShifts(unsortedShifts.sort((a, b) => {
            return (
                new Date(b.eventInfo.calendarTime).getTime() -
                new Date(a.eventInfo.calendarTime).getTime()
            )
        }));
    }, [data, employee, shifts])

    if (isLoading) {
        return (
            <div className={styles.container}>
                <div className={styles.loadingState}>
                    <Spinner size='xl' color="purple.500" />
                </div>
            </div>
        )
    }

    if (error || data?.error || !employee) {
        return (
            <div></div>
        )
    }

    return (
        <div className={styles.container}>
            <ClockInEmployeeCard
                employee={employee}
                events={shifts}
            />
        </div>
    )
}

export default ShiftHistory;