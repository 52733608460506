// TODO: remove this style sheet after identify and extracting the relevant CSS
import 'react-rrule-generator-semantic/build/styles.css';
import { useAuth } from "@contexts/auth_context";
import { useEffect } from 'react';
import {
  Flex, Button, Checkbox, Box, Text, Spinner, IconButton, Select
} from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from "./styles.module.scss";
import {
  format, addWeeks, startOfWeek, subWeeks, addDays, parseISO, addSeconds, isSameDay
} from "date-fns";
import { useState } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { ErrorPage } from '@/components/ErrorPage';
import { ScheduleEditModal } from "@/components/ScheduleEditModal";
import useSWR, { useSWRConfig } from 'swr';
import { EventFilter } from "@/components/EventFilter";
import { renderUserName } from "@/utils/formatting";


const getUrl = (inputDate: Date) => {
  return `${import.meta.env.VITE_API_SERVER}/assignments/events.json?start_datetime=${encodeURIComponent(inputDate.toISOString())}&end_datetime=${encodeURIComponent(addWeeks(inputDate, 1).toISOString())}`;
}

const renderSpinner = () => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Spinner size='xl' color="purple.500" />
    </Flex>
  );
}

const isEventUnfilled = (event) => {
  return event.employeeId === null;
}

const SchedulesPage = () => {
  const { date } = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const authContext = useAuth();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig()

  const thisMonday = startOfWeek(new Date(), { weekStartsOn: 1 });
  const [startDatetime, setStartDatetime] = useState<Date>(date ? startOfWeek(parseISO(date), {weekStartsOn: 1}) : thisMonday);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [filterByUnfilled, setFilterByUnfilled] = useState(false);

  // Filtering
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [buildingFilteredEventIds, setBuildingFilteredEventIds] = useState<Set<string>>(new Set());
  const [supervisorFilteredEventIds, setSupervisorFilteredEventIds] = useState<Set<string>>(new Set());
  const [employeeFilteredEventIds, setEmployeeFilteredEventIds] = useState<Set<string>>(new Set());
  const [isBuildingFilterLoading, setIsBuildingFilterLoading] = useState<boolean>(true);
  const [isSupervisorFilterLoading, setIsSupervisorFilterLoading] = useState<boolean>(true);
  const [isEmployeeFilterLoading, setIsEmployeeFilterLoading] = useState<boolean>(true);

  const eventIdSetsFromOtherFilters = (eventIdSetForCurrentFilter) => {
    return [buildingFilteredEventIds, supervisorFilteredEventIds, employeeFilteredEventIds]
    .filter((eventIdSet) => {
      return eventIdSet != eventIdSetForCurrentFilter;
    });
  }

  const getEventId = (event) => {
    return event.assignmentId + "-" + event.calendarStartDatetime;
  }

  const rerenderFilters = () => {
    // hide all events before rerendering to avoid flickering.
    setBuildingFilteredEventIds(new Set());
    setSupervisorFilteredEventIds(new Set());
    setEmployeeFilteredEventIds(new Set());
    setResetKey(!resetKey);
  }

  const setEventFilterLoading = () => {
    setIsBuildingFilterLoading(true);
    setIsSupervisorFilterLoading(true);
    setIsEmployeeFilterLoading(true);
  }

  useEffect(() => {
    if (!date) {
      navigate(`/schedules/${format(startDatetime, 'yyyy-MM-dd')}?${searchParams.toString()}`, { replace: true });
    }
  }, [date]);

  const setStartDateTimeAndUpdateUrl = (newStartDate: Date) => {
    if (newStartDate.getTime() == startDatetime.getTime()) {
      return;
    }
    setEventFilterLoading();
    setStartDatetime(newStartDate);
    navigate(`/schedules/${format(newStartDate, 'yyyy-MM-dd')}?${searchParams.toString()}`);
  };

  const fetchEvents = async (url: String) => {
    const response = await authContext.authenticatedFetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  };

  const { data, error, isLoading } = useSWR(
    getUrl(startDatetime),
    url => fetchEvents(url)
  );

  useEffect(() => {
    // useEffect has to be above any early returns
    // https://github.com/vercel/swr/discussions/1815
    if (data && data.events) {
      // This applies the filters to the just loaded events.
      // This will also eventually set the filters to be loaded.
      rerenderFilters();
    } else {
      // Set filters to be loading when data is reset to null.
      setEventFilterLoading();
    }
  }, [data]);

  if (error) {
    return <ErrorPage error={error}/>;
  }

  const { locations, users, assignments } = data || {};
  const allEvents = data?.events || [];
  const filteredEvents = allEvents.filter((event) => {
    return buildingFilteredEventIds.has(getEventId(event))
    && supervisorFilteredEventIds.has(getEventId(event))
    && employeeFilteredEventIds.has(getEventId(event))
    && (!filterByUnfilled || isEventUnfilled(event));
  });

  const renderTableHeader = () => {
    const headerCells = [];
    headerCells.push(
      <th key="name" className={styles.firstColumn}/>
    );
    for (let i = 0; i < 7; i++) {
      const date = addDays(startDatetime, i);
      const dayOfWeek = format(date, 'EEE').toUpperCase();
      const dayOfMonth = format(date, 'd');
      const isToday = isSameDay(date, new Date());

      headerCells.push(
        <th key={"day_" + i} style={{ flexGrow: 1 }}>
          <Text fontSize={11}>{dayOfWeek}</Text>
          <Box
            borderRadius="md"
            backgroundColor={isToday ? "primary.500" : "white"}
            display="inline-block"
            paddingRight={4}
            paddingLeft={4}
            paddingTop={2}
            paddingBottom={2}
            marginBottom={4}
            marginTop={2}>
            <Text fontSize={18} color={isToday ? "white" : "black"}>{dayOfMonth}</Text>
          </Box>
        </th>
      );
    }
    return (
      <Box className={styles.headerContainer}>
        <table className={styles.scheduleTable}>
          <thead>
            <tr>
              {headerCells}
            </tr>
          </thead>
        </table>
      </Box>
    )
  }

  const renderCell = (event, day) => {
    if (!event) {
      return  <td key={day}/>;
    }
    const isUnfilled = isEventUnfilled(event);
    const redBackground = `linear-gradient(135deg, #EF6666 10%, rgba(239, 102, 102, 0.92) 10%, rgba(239, 102, 102, 0.92) 20%, #EF6666 20%, #EF6666 30%, rgba(239, 102, 102, 0.92) 30%, rgba(239, 102, 102, 0.92) 40%, #EF6666 40%, #EF6666 50%, rgba(239, 102, 102, 0.92) 50%, rgba(239, 102, 102, 0.92) 60%, #EF6666 60%, #EF6666 70%, rgba(239, 102, 102, 0.92) 70%, rgba(239, 102, 102, 0.92) 80%, #EF6666 80%, #EF6666 90%, rgba(239, 102, 102, 0.92) 90%, rgba(239, 102, 102, 0.92) 100%)`
    const time = format(new Date(event.calendarStartDatetime), 'hh:mm a')
    + " - " + format(addSeconds(new Date(event.calendarStartDatetime), event.calendarDuration), 'hh:mm a')

    const textColor = isUnfilled ? "white" : undefined;
    const backgroundImage = isUnfilled ? redBackground : undefined;
    const backgroundColor = isUnfilled ? undefined : "#DAE2FF";
    return (
      <td onClick={() => {
        setIsEditModalOpen(true);
        setSelectedEvent(event);
      }} key={day}>
        <Box
          borderRadius="md"
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          p={2}
          m={1}
          cursor="pointer"
        >
          <Text
            fontSize={10}
            color={textColor}
          >
            {time}
          </Text>
          <Text
            fontWeight={"medium"}
            fontSize={12}
            marginTop={1}
            color={textColor}
          >
            {renderUserName(users, event.employeeId)}
          </Text>
        </Box>
      </td>
    )
  }

  const renderTableBody = (locations, users, assignments, events) => {
    const eventsByAssignmentId = events.reduce((acc, event) => {
      if (!acc[event.assignmentId]) {
        acc[event.assignmentId] = {};
      }
      // Adjust to start from Monday
      const dayOfWeek = (new Date(event.calendarStartDatetime).getDay() + 6) % 7;
      if (!acc[event.assignmentId][dayOfWeek]) {
        acc[event.assignmentId][dayOfWeek] = event;
      } else {
        // TODO: there should only be one event per day. If that's not the case, report error to Sentry
      }
      return acc;
    }, {});

    const assignmentRows = []
    Object.values(assignments || {}).forEach((assignment) => {
      const events = eventsByAssignmentId[assignment.id];
      if (!events) {
        return;
      }
      const daysOfWeek = Array.from({ length: 7 }, (_, day) => events?.[day] || null);
      const location = locations[assignment.locationId];
      const firstEvent = Object.values(events)[0];
      assignmentRows.push({
        location,
        daysOfWeek,
        employeeName: renderUserName(users, firstEvent.employeeId),
        calendarStartDatetime: new Date(firstEvent.calendarStartDatetime).getTime() });
      location.lastUpdatedAt = Math.max(location.lastUpdatedAt || 0, new Date(assignment.updatedAt).getTime());
    });

    assignmentRows.sort((a, b) => {
      return (
        a.location.name.localeCompare(b.location.name) ||
        a.employeeName.localeCompare(b.employeeName) ||
        a.calendarStartDatetime - b.calendarStartDatetime
      );
    });

    let lastLocationName = null;
    return (
      <table className={styles.scheduleTable} >
        <tbody>
          {assignmentRows.map((assignmentRow, index) => {
            const thisLocationName = assignmentRow.location.name;
            const sameLocation = lastLocationName === thisLocationName;
            lastLocationName = thisLocationName;
            return (
            <tr className={sameLocation ? styles.noBorderTop : undefined} key={index}>
              <td className={styles.firstColumn}>
                {!sameLocation ? <Text fontSize={12} pt={2}>{assignmentRow.location.name}</Text> : null}
              </td>
              {assignmentRow.daysOfWeek.map((event, day) => renderCell(event, day))}
            </tr>
          )})}
        </tbody>
      </table>
    );
  }

  return (
    <Flex
      padding={6}
      height={"100%"}
      flexDirection={"column"}
    >
      <Flex minWidth='max-content' flexDirection={"column"} gap='2'>

        <Flex justifyContent={"space-between"} width='100%'>
          <Flex alignItems={"center"}>
            <IconButton
              variant="outline"
              fontSize='24px'
              colorScheme={"gray"}
              textColor={"secondary.deepGray"}
              onClick={() => setStartDateTimeAndUpdateUrl(subWeeks(startDatetime, 1))}
              icon={<MdOutlineKeyboardArrowLeft/>}
            />
            <Text
              width={32}
              textAlign={"center"}
            >
              {format(startDatetime, 'MMM d') + " - " + format(addDays(startDatetime, 6), 'MMM d')}
            </Text>
            <IconButton
              variant="outline"
              colorScheme={"gray"}
              textColor={"secondary.deepGray"}
              onClick={() => setStartDateTimeAndUpdateUrl(addWeeks(startDatetime, 1))}
              fontSize='24px'
              icon={<MdOutlineKeyboardArrowRight/>}
            />
            <Button
              variant="outline"
              colorScheme={"gray"}
              textColor={"secondary.deepGray"}
              marginLeft={6}
              onClick={() => setStartDateTimeAndUpdateUrl(thisMonday)}
            >
              This week
            </Button>
          </Flex>
          <Button
            isDisabled={isLoading}
            onClick={() => {
              setSelectedEvent(null);
              setIsEditModalOpen(true);
          }}>
            New schedule
          </Button>
        </Flex>
      </Flex>

      <Box borderRadius="md"
        flex={1}
        backgroundColor="white"
        overflow="hidden"
        p={4}
        boxShadow="sm"
        display="flex"
        flexDirection="column"
        marginTop={6}
        paddingLeft={6} paddingRight={6} paddingTop={0} paddingBottom={6}
      >
        <Flex
          width='100%'
          alignItems={"center"}
          justifyContent={"space-between"}
          pb={6} pt={4}
        >
          <Flex
            alignItems={"center"}
          >
            <Text fontSize={14} mr={4}>Filter by: </Text>
            <Box mx={2}>
              <EventFilter
                key={"SupervisorFilter" + resetKey}
                isLoading={isSupervisorFilterLoading}
                events={allEvents}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(supervisorFilteredEventIds)}
                getFilterKey={(event) => renderUserName(users, event.supervisorId)}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={(eventIds) => {
                  setSupervisorFilteredEventIds(eventIds);
                  setIsSupervisorFilterLoading(false);
                }}
                filterName={"Supervisor"}
              />
            </Box>
            <Box mx={2}>
              <EventFilter
                key={"BuildingFilter" + resetKey}
                isLoading={isBuildingFilterLoading}
                events={allEvents}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(buildingFilteredEventIds)}
                getFilterKey={(event) => locations[event.locationId].name}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={(eventIds) => {
                  setBuildingFilteredEventIds(eventIds);
                  setIsBuildingFilterLoading(false);
                }}
                filterName={"Building"}
                supplementKeys={Object.values(locations || {}).map((location) => location.name)}
              />
            </Box>
            <Box mx={2}>
              <EventFilter
                key={"EmployeeFilter" + resetKey}
                isLoading={isEmployeeFilterLoading}
                events={allEvents}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(employeeFilteredEventIds)}
                getFilterKey={(event) => renderUserName(users, event.employeeId)}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={(eventIds) => {
                  setEmployeeFilteredEventIds(eventIds);
                  setIsEmployeeFilterLoading(false);
                }}
                filterName={"Cleaner"}
                supplementKeys={Object.values(users || {}).map((user) => user.name)}
              />
            </Box>
            <Checkbox
              isChecked={filterByUnfilled}
              onChange={() => setFilterByUnfilled(!filterByUnfilled)}
              fontSize={14}
              ml={4}
             >Unfilled</Checkbox>
            <Button
              variant={"ghost"}
              onClick={() => {
                setEventFilterLoading();
                setSearchParams('');
                rerenderFilters();
                setFilterByUnfilled(false);
              }}
            >
              Clear all
            </Button>
          </Flex>
        </Flex>
        {renderTableHeader()}
        <Box overflowY="auto" flex={1} className={styles.tableBodyContainer}>
          {isLoading ? renderSpinner() : renderTableBody(locations, users, assignments, filteredEvents)}
        </Box>
      </Box>

      <ScheduleEditModal
        isOpen={isEditModalOpen}
        event={selectedEvent}
        assignments={assignments}
        locations={locations}
        users={users}
        close={(triggerRefresh: boolean = false) => {
          setIsEditModalOpen(false);
          setSelectedEvent(null);
          if (triggerRefresh) {
            mutate(getUrl(startDatetime))
          }
        }}
      />
    </Flex>
  );
};

export default SchedulesPage;
