const sameDate = (d1: Date, d2: Date): boolean => {
  return d1.getUTCFullYear() === d2.getUTCFullYear() &&
  d1.getUTCMonth() === d2.getUTCMonth() &&
  d1.getUTCDate() === d2.getUTCDate();
}

const differenceInDays = (d1: Date, d2: Date): number => (
  Math.round((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24))
)

const formatActivityTimestamp = (date: Date): string => {
  const currentDate = new Date();
  if (sameDate(date, currentDate)) {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit' });
  }

  return `${differenceInDays(date, currentDate)} days ago`
}

export { sameDate, formatActivityTimestamp }
