import { useParams } from 'react-router-dom';
import {
  Heading,
  Text,
  Flex,
  Image,
  Box,
  Skeleton,
  useTheme,
  IconButton
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAuth } from '@contexts/auth_context';
import useSWR from 'swr';
import { FullScreenSpinner } from '@/components/FullScreenSpinner';
import { ErrorPage } from '@/components/ErrorPage';
import thumbsUp from '../../assets/thumbs_up.png';
import thumbsDown from '../../assets/thumbs_down.png';
import unrated from '../../assets/unrated.png';
import calendarIcon from '../../assets/calendar.png';
import poiIcon from '../../assets/poi.png';
import profileIcon from '../../assets/profile.png';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { InspectionScore } from '@/components/InspectionScore';
import { pdf } from '@react-pdf/renderer';
import { InspectionPdf } from '@/components/InspectionPdf';
import { MdCloudDownload } from 'react-icons/md';
import { PhotoList } from '@/components/PhotoList';
import { IPhoto } from '@/components/PhotoList/photo_list';

const InspectionPage = () => {
  const { inspection_id } = useParams();
  const authContext = useAuth();
  const theme = useTheme();

  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const [slides, setSlides] = useState<Array<IPhoto>>([]);

  const { data, error, isLoading } = useSWR(
    inspection_id,
    inspection_id => {
      return authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/inspections/${inspection_id}`, {
        method: 'get',
      }).then(response => response.json());
    },
    {
      revalidateOnFocus: false,
    }
  );

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (data?.error) {
    return <ErrorPage errorMessage={data.error} />;
  }

  const renderRatingIcon = (rating) => {
    switch (rating) {
      case 'thumbs_up':
        return <Image src={thumbsUp} boxSize="40px" />;
      case 'thumbs_down':
        return <Image src={thumbsDown} boxSize="40px"  />;
      default:
        return <Image src={unrated} boxSize="40px"  />;
    }
  };

  const { locations, users, inspection, inspectionForm } = data;

  const generatePDF = async () => {
    const blob = await pdf(<InspectionPdf inspection={inspection} inspectionForm={inspectionForm} location={locations[inspection.locationId]}/>).toBlob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${locations[inspection.locationId].name}_${new Date(inspection.createdAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>

      <Lightbox
        index={lightboxIndex}
        slides={slides}
        open={lightboxIndex >= 0}
        close={() => setLightboxIndex(-1)}
        plugins={[Zoom]}
      />

      <Flex
        flexDirection={"column"}
      >
        <Flex
          flexDirection={'column'}
          px={6}
          ml={'1px'} // So we don't cover up the shadow of the sidebar. Needed this because I couldn't get z-index to work
          backgroundColor={"white"}
          zIndex={1}
          position={'sticky'}
          top={"0"}
        >
          <Heading
            as="h1"
            size="lg"
            pt={8}
            pb={6}
            marginBottom={0}
          >
            <Text>{locations[inspection.locationId].name}</Text>
          </Heading>

          <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems="center" py={6}>
            <Flex flexDirection={"row"} alignItems={"center"}>
              <InspectionScore
                score={inspection.score}
                circleSize={40}
                textSize={17}
              />
              <Text fontSize={22} fontWeight="medium" ml={6}>Overall score</Text>
            </Flex>
            <Flex flexDirection={"row"} alignItems="center">
              <Image ml={4} mr={2} src={profileIcon} boxSize="20px" />
              <Text
                fontSize={16}
              >
                {users[inspection.userId].name}
              </Text>
              <Image ml={4} mr={2} src={calendarIcon} boxSize="20px" />
              <Text
                fontSize={16}
              >
                {new Date(inspection.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </Text>
              <Image ml={4} mr={2} src={poiIcon} boxSize="20px" />
              <Text
                fontSize={16}
              >
                {locations[inspection.locationId].address}
              </Text>
              <IconButton
                aria-label='Call Sage'
                fontSize='16px'
                size={'sm'}
                ml={4} mr={2}
                icon={<MdCloudDownload />}
                onClick={generatePDF}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} mb={6}>
          {inspectionForm.areas.map((area) => (
            <Flex key={area.id} mx={6} mt={6} flexDirection={'column'}>
              <Text fontSize={18} mb={4} fontWeight="medium">{area.name}</Text>
              {area.items.map((item) => (
                <Flex
                  key={item.id}
                  borderRadius="3xl"
                  flex={1}
                  backgroundColor="white"
                  border={`1px solid ${theme.colors.gray[200]}`}
                  flexDirection="column"
                  mt={2}
                  p={6}
                >
                  <Flex alignItems="center">
                    {renderRatingIcon(item.rating)}
                    <Text fontWeight="medium" fontSize={22} ml={6}>{item.name}</Text>
                  </Flex>
                  {item.comment && <Text mt={4}>{item.comment}</Text>}

                  <PhotoList
                    urls={item.photoUrls || []}
                    onClickPhoto={(photos, photo) => {
                      setSlides(photos);
                      setLightboxIndex(photo.photoIndex);
                    }} />

                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default InspectionPage;
