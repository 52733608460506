import useSWR from "swr";

import ReactECharts from 'echarts-for-react';
import { Box, Flex, Text, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";
import { camelCaseToTitleCase, toShortDate } from "@/utils/formatting";

import ChartLoadingState from "./loading_state";

import styles from "./styles.module.scss";

const BudgetedVsClocked = ({ budgeted, clocked }) => {
  const theme = useTheme()

  let option = {
    textStyle: {
      fontFamily: "DM Sans"
    },
    grid: {
      top: "5%",
      left: "10%",
      bottom: "20%",
      right: "5%",
    },
    xAxis: {
      type: 'time',
      minInterval: 1000 * 60 * 60 * 24 * 30,
      maxInterval: 1000 * 60 * 60 * 24 * 30,
      axisLabel: {
        color: "black",
        formatter: "{MMM}",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: true,
        interval: 2,
        lineStyle: {
          color: theme.colors.secondary.gray,
          type: [8, 8],
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: theme.colors.secondary.darkGray,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: false,
      }
    },
    tooltip: {
      show: true,
      trigger: "axis",
      confine: true,
      className: styles.toolTip,
      formatter: (params) => {
        return `
        <div style="display: flex; flex-direction: column;">
          <div style="display: flex; justify-content: space-between;">
            <div style="flex: 0 1 auto; color: black; text-align: left; font-weight: bold; margin-bottom: 0.5em;">Week Starting ${toShortDate(params[0]?.axisValue) || toShortDate(params[1]?.axisValue)}</div>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div style="flex: 0 1 auto; text-align: left; font-weight: bold; margin-right: 1em;">Budgeted Hours</div>
            <div style="flex: 1; text-align: right;">${params[0]?.value[1] || "0"}</div>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div style="flex: 0 1 auto; text-align: left; font-weight: bold; margin-right: 1em;">Clocked Hours</div>
            <div style="flex: 1; text-align: right;">${params[1]?.value[1] || "0"}</div>
          </div>
        </div>
        `
      },
    },
    legend: {
      orient: 'horizontal',
      icon: 'circle',
      bottom: 0,
    },
    series: [
      {
        name: "Budgeted",
        type: "line",
        showSymbol: false,
        itemStyle: {
          color: theme.colors.secondary.green
        },
        smooth: true,
        emphasis: {
          focus: "series",
        },
        data: budgeted.map(point => [
          new Date(point.timestamp * 1000).setHours(0, 0, 0, 0),
          (point.value / (60 * 60)).toFixed(1)
        ])
      },
      {
        name: "Clocked",
        type: "line",
        showSymbol: false,
        itemStyle: {
          color: theme.colors.secondary.yellow
        },
        smooth: true,
        emphasis: {
          focus: "series",
        },
        data: clocked.map(point => [
          new Date(point.timestamp * 1000).setHours(0, 0, 0, 0),
          (point.value / (60 * 60)).toFixed(1)
        ])
      }
    ]
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.5em"}
      width={"49%"}
      maxHeight={"25em"}
    >
      <Text fontSize={"lg"} fontWeight={"medium"} marginBottom={"0.5em"}>Budgeted vs Clocked Hours</Text>
      <ReactECharts option={option} style={{ height: "90%" }} />
    </Box>
  )
}

const MissedPunches = ({ data }) => {
  const theme = useTheme()

  const categoryColors = {
    "noShow": theme.colors.secondary.red,
    "missedClockOut": theme.colors.secondary.yellow,
    "completed": theme.colors.secondary.green,
  }
  let option = {
    textStyle: {
      fontFamily: "DM Sans"
    },
    grid: {
      top: "5%",
      left: "10%",
      bottom: "20%",
      right: "5%",
    },
    xAxis: {
      type: 'time',
      minInterval: 1000 * 60 * 60 * 24 * 30,
      maxInterval: 1000 * 60 * 60 * 24 * 30,
      axisLabel: {
        color: "black",
        formatter: "{MMM}",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: true,
        interval: 2,
        lineStyle: {
          color: theme.colors.secondary.gray,
          type: [8, 8],
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: theme.colors.secondary.darkGray,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: false,
      }
    },
    tooltip: {
      show: true,
      trigger: "axis",
      confine: true,
      className: styles.toolTip,
      formatter: (params) => {
        return `
        <div style="display: flex; flex-direction: column;">
          <div style="display: flex; justify-content: space-between;">
          <div style="flex: 0 1 auto; color: black; text-align: left; font-weight: bold; margin-bottom: 0.5em;">
            Week Starting ${toShortDate(params[0]?.axisValue)}
          </div>
          </div>
          ${params.toReversed().map((param =>
          `
            <div style="display: flex; justify-content: space-between;">
              <div style="flex: 0 1 auto; text-align: left; font-weight: bold; margin-right: 1em;">${param.seriesName}</div>
              <div style="flex: 1; text-align: right;">${param?.value[1] || 0}</div>
            </div>
            `
        )).join("")}
        </div>
        `
      },
    },
    legend: {
      orient: 'horizontal',
      icon: 'circle',
      bottom: 0,
    },
    series: Object.entries(data).map(([category, points]) => ({
      name: camelCaseToTitleCase(category),
      type: "bar",
      stack: "All Shifts",
      showSymbol: false,
      itemStyle: {
        color: categoryColors[category],
      },
      smooth: true,
      emphasis: {
        focus: 'series'
      },
      data: points.map(({ timestamp, value }) =>
        [new Date(timestamp * 1000).setHours(0, 0, 0, 0), value]
      )
    }))
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.5em"}
      width={"49%"}
      maxHeight={"25em"}
    >
      <Text fontSize={"lg"} fontWeight={"medium"} marginBottom={"0.5em"}>Shifts with Missed Punches</Text>
      <ReactECharts option={option} style={{ height: "90%" }} />
    </Box>
  )
}


const AttendanceCharts = ({ locationId }) => {
  const authContext = useAuth();
  const theme = useTheme();

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/locations/${locationId}/metrics/attendance`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return (
      <Flex flexDirection={"row"} justifyContent={"space-between"} minHeight={"20em"}>
        <ChartLoadingState
          spinnerSize={"4em"}
          backgroundColor={"white"}
          border={`1px solid ${theme.colors.gray[200]}`}
          height={"100%"}
          width={"49%"}
        />
        <ChartLoadingState
          spinnerSize={"4em"}
          backgroundColor={"white"}
          border={`1px solid ${theme.colors.gray[200]}`}
          height={"100%"}
          width={"49%"}
        />
      </Flex>
    )
  }


  return (
    <Flex flexDirection={"row"} justifyContent={"space-between"} minHeight={"20em"}>
      <BudgetedVsClocked budgeted={data.dataSeries.budgetedTime} clocked={data.dataSeries.clockedTime} />
      <MissedPunches data={{
        noShow: data.dataSeries.missedShifts,
        missedClockOut: data.dataSeries.missedClockOutShifts,
        completed: data.dataSeries.completedShifts,
      }} />
    </Flex>
  )
}

export default AttendanceCharts;
