import { Flex } from '@chakra-ui/react';

const ErrorPage = ({ error, errorMessage }) => {
  const message = error?.statusText || error?.message || errorMessage;

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{message}</i>
        </p>
      </div>
    </Flex>
  );
};

export default ErrorPage;
