import { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import styles from "./user_edit_modal.module.scss";
import { useAuth } from "./../../contexts/auth_context";

const UserEditModal = ({
  isOpen,
  onClose,
  user}) => {

  const isEdit = user ? true : false;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSupervisor , setIsSupervisor] = useState(false);
  const authContext = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setName(user?.name || '');
    setPhone(user?.phoneNumber || '');
    setIsSupervisor(user?.isSupervisor || false);
  }, [isOpen]);

  const handleSubmit = () => {
    setIsLoading(true);
    authContext.authenticatedFetch(isEdit ? `${import.meta.env.VITE_API_SERVER}/users/${user.id}` : `${import.meta.env.VITE_API_SERVER}/users`, {
      method: isEdit ? "PUT" : "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        phone,
        isSupervisor,
      }),
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      onClose();
    }).catch((error) => {
      toast({
        description: error.message,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    }).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEdit ? "Edit User" : "Create User"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
              />
            </FormControl>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Phone Number</FormLabel>
              <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter phone number"
              />
            </FormControl>
            <FormControl className={styles.checkboxContainer}>
              <Checkbox
                isChecked={isSupervisor}
                onChange={(e) => setIsSupervisor(e.target.checked)}
              />
              <FormLabel className={styles.checkboxLabel}>Supervisor</FormLabel>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup width="100%" spacing='6'>
            <Button flex={1} variant='solid'
              isLoading={isLoading}
              onClick={handleSubmit}>
              {isEdit ? "Update" : "Create"}
            </Button>
            </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserEditModal;
