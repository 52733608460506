import { PhotoList } from "@/components/PhotoList";
import { formatActivityTimestamp } from "@/utils/date_helpers";
import { Flex, useTheme, Text } from "@chakra-ui/react";

import styles from "./styles.module.scss";
import { IIssueComment } from "@/types/issue";
import { useContext } from "react";
import { IssueDetailContext } from "@/views/IssueDetailPage/issue_detail_page";

interface IIssueCommentProps {
  comment: IIssueComment;
}

const Comment = (props: IIssueCommentProps) => {
  const { comment } = props;
  const theme = useTheme();
  const issueDetailContext = useContext(IssueDetailContext);
  const { setSlides, setLightboxIndex } = issueDetailContext;

  return (
    <Flex flexDirection="column" className={styles.content} style={{ backgroundColor: theme.colors.secondary.lightGray }}>
      <Flex flexDirection="row" justifyContent="space-between" marginBottom="16px" fontSize="12px">
        <Text className={styles.creatorName}>{ comment.creator?.name }</Text>
        <Text>{ comment?.createdAt ? formatActivityTimestamp(comment?.createdAt) : '' }</Text>
      </Flex>


      <Flex flexDirection="column">
        {/* { comment.isFixComment ? <View style={{ display: "flex", marginLeft: "auto", marginRight: 0, backgroundColor: "white" }}><Tag backgroundColor="#D2F9E4" textColor="#4FC980" text="FIX" /></View> : null } */}
        { comment.text ? <Text>{comment.text}</Text> : null }
        { comment.attachments ? (
          <PhotoList
            urls={comment.attachments}
            onClickPhoto={(photos, photo) => {
              setSlides(photos);
              setLightboxIndex(photo.photoIndex);
            }}
          />
        ) : null }
      </Flex>

      
    </Flex>
  );
};

export default Comment;
