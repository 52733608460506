import DatePicker from 'react-datepicker';
import "./datepicker-styles.css";
import styles from "./styles.module.scss";

interface IDateTimePickerProps {
  value: any;
  onChange: (date: Date) => void;
  showTime?: boolean;
  showDate?: boolean;
  popperPlacement?: any;
  disabled?: boolean;
  alignment?: "left" | "center" | "right";
  date?: Date;
}

const DateTimePicker = (props: IDateTimePickerProps) => {
  const { value, onChange, showTime=true, showDate=true, popperPlacement="bottom", disabled=false, alignment="center", date } = props;

  let dateFormat = "MM/dd/yyyy h:mm aa";
  if (showDate && !showTime) {
    dateFormat = "MM/dd/yyyy";
  } else if (!showDate && showTime) {
    dateFormat = "h:mm aa";
  } else if (!showDate && !showTime) {
    throw new Error("DateTimePicker must show either date or time");
  }

  if (!showDate && !date) {
    throw new Error("DateTimePicker must have a date prop when not showing date, to avoid date being set as 2000/01/01");
  }

  return (
    <DatePicker
      selected={value}
      disabled={disabled}
      onChange={onChange}
      showPopperArrow={false}
      disabledKeyboardNavigation
      showTimeSelect={showTime}
      autoComplete="off"
      minDate ={!showDate ? date : undefined}
      maxDate ={!showDate ? date : undefined}
      showTimeSelectOnly={showTime && !showDate}
      dateFormat={dateFormat}
      wrapperClassName={[styles.wrapper, disabled ? styles.disabled : "", styles[alignment]].join(" ")}
      popperPlacement={popperPlacement}
    />
  );
};

export default DateTimePicker;
