const camelCaseToTitleCase = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const formatDuration = (duration) => {
  if (duration === 0) {
    return null;
  }
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);
  let text = "";
  if (hours > 0) {
    text += `${hours} h`;
  }
  if (minutes > 0) {
    text += ` ${minutes} m`;
  }
  return text;
};

const renderUserName = (userMap, userId, missingUserText = "Unfilled") => {
  if (!userId) {
    return missingUserText;
  }
  const user = userMap[userId];
  if (!user) {
    return "Inactive User (" + userId + ")";
  }
  return user.name;
};

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "";
  }
  if (phoneNumber.length !== 11 || phoneNumber[0] !== "1") {
    return phoneNumber;
  }
  return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
    1,
    4
  )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
};

const formatCurrency = (amount) => {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
};

const toShortDate = (date) => {
  const options = { month: "short", day: "numeric" };
  return new Date(date).toLocaleString("en-EN", options);
};

export {
  camelCaseToTitleCase,
  formatDuration,
  renderUserName,
  formatPhoneNumber,
  formatCurrency,
  toShortDate,
};
